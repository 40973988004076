import React from 'react'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <p
        style={{
          color: '#6d6f71',
          fontSize: '11px',
        }}
      >
        {t('copyright')}
      </p>
    </footer>
  )
}

export default Footer
