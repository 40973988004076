/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Card,
  Picklist,
  PicklistOption,
  Button,
  TimePicker,
  CheckboxToggle,
  ButtonIcon,
  Input,
} from 'react-rainbow-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fi, sv, enUS } from 'date-fns/locale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleLeft,
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import * as API from '../../utilities/api'
import './styles.scss'
import 'moment/locale/fi'

const OldEntriesList = ({ history }) => {
  const [dbResult, setDbResult] = useState({})
  const [queryUrl, setQueryUrl] = useState(null)
  const [filteredEvents, setFilteredEvents] = useState([])
  const [eventName, setEventName] = useState(null)
  const [date, setDate] = useState(new Date())
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [time, setTime] = useState(null)
  const [cardNumber, setCardNumber] = useState('')
  const [isDateRange, setIsDateRange] = useState(false)
  const [relatedTargetUnits, setRelatedTargetUnits] = useState([])
  const [selectedTargetUnit, setSelectedTargetUnit] = useState(null)
  const [targetUnitEventNames, setTargetUnitEventNames] = useState([])
  moment.locale('fi')
  const { i18n, t } = useTranslation()

  const getLocale = () => {
    switch (i18n.language) {
      case 'fi':
        return fi
      case 'sv':
        return sv
      case 'en':
        return enUS
      default:
        return fi
    }
  }

  const clearFilters = () => {
    setIsDateRange(false)
    setQueryUrl(null)
    setDateRange([null, null])
    setDate(new Date())
  }

  const setDateRangeThisYear = () => {
    setIsDateRange(true)
    setDateRange([new Date(moment().startOf('year')), new Date(moment())])
  }

  const setDateRangeNextSixMonths = () => {
    setIsDateRange(true)
    setDateRange([new Date(moment()), new Date(moment().add(6, 'months'))])
  }

  useEffect(() => {
    const getTargetUnits = async () => {
      try {
        const res = await API.GET('api/v1/target_units/')
        if (res && res.data && res.data.results) {
          const { data } = res
          const units = data.results.map((unit) => ({
            id: unit.id,
            name: unit.name,
          }))
          setRelatedTargetUnits([
            ...units.sort((a, b) => a.name.localeCompare(b.name)),
            { id: t('oldEntries.noOffice'), name: t('oldEntries.noOffice') },
          ])
        } else {
          setRelatedTargetUnits([])
          setSelectedTargetUnit(null)
        }
      } catch (err) {
        setRelatedTargetUnits([])
        setSelectedTargetUnit(null)
      }
    }
    getTargetUnits()
  }, [])

  useEffect(() => {
    let isMounted = true
    const getTargetUnitEvents = async () => {
      try {
        const url =
          selectedTargetUnit && selectedTargetUnit.value
            ? `api/v1/events/?targetUnit=${
                selectedTargetUnit.value.label === t('oldEntries.all')
                  ? 'null'
                  : selectedTargetUnit.value.value
              }`
            : 'api/v1/events/'
        const res = await API.GET(url)
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const events = data.results
            setTargetUnitEventNames([
              ...events.sort((a, b) => a.name.localeCompare(b.name)),
              { id: t('oldEntries.noName'), name: t('oldEntries.noName') },
            ])
            setEventName(null)
          } else {
            setTargetUnitEventNames([])
            setEventName(null)
          }
        }
      } catch (err) {
        if (isMounted) {
          setTargetUnitEventNames([])
          setEventName(null)
        }
      }
    }
    getTargetUnitEvents()
    return () => {
      isMounted = false
    }
  }, [selectedTargetUnit])

  useEffect(() => {
    let isMounted = true
    const getPreviousEvents = async (
      dateFilter,
      timeFilter,
      dateRangeFilter,
      cardNumberFilter,
      eventNameFilter,
      targetUnitFilter
    ) => {
      let completeUrl = ''
      let res = null

      if (isDateRange && dateRangeFilter[0] && dateRangeFilter[1]) {
        // Filter based on date range
        const [start, end] = dateRangeFilter
        const formattedStart = moment(start).format('YYYY-MM-DD')
        const formattedEnd = moment(end).format('YYYY-MM-DD')
        completeUrl = `api/v1/entries/?rangeStart=${formattedStart}&rangeEnd=${formattedEnd}`
      } else {
        // Filter based on single date
        const formattedDate = moment(dateFilter).format('YYYY-MM-DD')
        completeUrl = `api/v1/entries/?eventDate=${formattedDate}`
      }

      if (timeFilter) {
        completeUrl += `&eventTime=${timeFilter}`
      }

      if (cardNumberFilter && cardNumberFilter !== '') {
        completeUrl += `&cardNumber=${cardNumberFilter}`
      }

      if (
        eventNameFilter &&
        eventNameFilter.value &&
        eventNameFilter.value.name
      ) {
        const eventNameValue =
          eventNameFilter.value.name === t('oldEntries.noName')
            ? 'null'
            : eventNameFilter.value.name

        completeUrl += `&eventName=${eventNameValue}`
      }

      if (
        targetUnitFilter &&
        targetUnitFilter.value &&
        targetUnitFilter.value.value
      ) {
        const targetUnitValue =
          targetUnitFilter.value.name === t('oldEntries.noOffice')
            ? 'null'
            : targetUnitFilter.value.value
        completeUrl += `&targetUnit=${targetUnitValue}`
      }

      try {
        res = await API.GET(completeUrl)
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const { results } = data
            setDbResult(data)
            const events = results.details
            setFilteredEvents(events)
            setQueryUrl(null)
          } else {
            setQueryUrl(null)
          }
        }
      } catch (error) {
        if (isMounted) {
          setDbResult({})
          setFilteredEvents([])
          setQueryUrl(null)
        }
      }
    }
    getPreviousEvents(
      date,
      time,
      dateRange,
      cardNumber,
      eventName,
      selectedTargetUnit
    )
    return () => {
      isMounted = false
    }
  }, [
    date,
    time,
    isDateRange,
    dateRange,
    cardNumber,
    eventName,
    selectedTargetUnit,
  ])

  useEffect(() => {
    let isMounted = true
    const getPreviousEvents = async (url) => {
      const urlArr = url.split('//')
      urlArr.splice(0, 1)
      const newUrl = urlArr.join('')
      try {
        const res = await API.GET(`//${newUrl}`, {}, false)
        if (isMounted) {
          if (res && res.data && res.data.results) {
            const { data } = res
            const { results } = data
            setDbResult(data)

            const events = results.details
            setFilteredEvents(events)
          }
        }
      } catch (error) {
        if (isMounted) {
          setDbResult({})
          setFilteredEvents([])
        }
      }
    }
    if (queryUrl) {
      getPreviousEvents(queryUrl)
    }
    return () => {
      isMounted = false
    }
  }, [queryUrl])

  return (
    <main className="old-entries-container">
      <div className="filter-wrapper">
        <Button
          type="button"
          onClick={() => history.push('/operator')}
          variant="base"
        >
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            className="rainbow-m-right_medium"
          />
          {t('back')}
        </Button>

        <div className="divider-container">
          <div className="horizontal-line" />
          <h2>{t('oldEntries.filterEntries')}</h2>
          <div className="horizontal-line" />
        </div>

        <div style={{ width: '300px' }}>
          <Button type="button" onClick={clearFilters} variant="brand">
            {t('oldEntries.today')}
          </Button>
        </div>

        <div style={{ width: '300px' }}>
          <Button type="button" onClick={setDateRangeThisYear} variant="brand">
            {t('oldEntries.beginningOfThisYear')}
          </Button>
        </div>

        <div style={{ width: '300px' }}>
          <Button
            type="button"
            onClick={setDateRangeNextSixMonths}
            variant="brand"
          >
            {t('oldEntries.nextSixMonths')}
          </Button>
        </div>

        {!isDateRange && (
          <DatePicker
            name="Event date"
            id="eventDate"
            label={t('oldEntries.eventDate')}
            locale={getLocale()}
            dateFormat="dd.MM.yyyy"
            onChange={(value) => setDate(value)}
            selected={date}
            value={new Date(date)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
          />
        )}
        {isDateRange && (
          <DatePicker
            id="eventDateRange"
            label={t('oldEntries.eventsFromDateRange')}
            placeholder={t('oldEntries.startAndEndDate')}
            dateFormat="dd.MM.yyyy"
            startDate={startDate}
            endDate={endDate}
            selectsRange
            locale={getLocale()}
            value={dateRange}
            onChange={(value) => setDateRange(value)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
          />
        )}

        <div style={{ padding: '5px' }}>
          <CheckboxToggle
            id="checkbox-toggle-component-1"
            label={t('oldEntries.startAndEndDate')}
            value={isDateRange}
            onChange={() => setIsDateRange(!isDateRange)}
          />
        </div>
        <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
          <TimePicker
            name="Event time"
            id="eventTime"
            label={t('oldEntries.eventTime')}
            value={time}
            onChange={(value) => setTime(value)}
            hour24
            style={{ maxWidth: '300px', width: '100%', height: '100%' }}
            cancelLabel={t('entry.cancel')}
          />
          <div style={{ position: 'absolute', top: '12px', right: '40px' }}>
            <ButtonIcon
              tooltip={t('clear')}
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setTime(null)}
            />
          </div>
        </div>

        <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
          <Input
            label={t('oldEntries.cardNumber')}
            placeholder={t('oldEntries.cardNumberPlaceholder')}
            type="search"
            onChange={(e) => setCardNumber(e.target.value)}
            value={cardNumber}
            iconPosition="right"
            icon={
              <FontAwesomeIcon
                icon={faSearch}
                className="rainbow-color_gray-3"
              />
            }
          />
          <div style={{ position: 'absolute', top: '12px', right: '40px' }}>
            <ButtonIcon
              tooltip={t('clear')}
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setCardNumber('')}
            />
          </div>
        </div>
        <Picklist
          placeholder={t('oldEntries.select')}
          label={t('entry.office')}
          style={{
            maxWidth: '300px',
            width: '100%',
            height: '100%',
          }}
          value={selectedTargetUnit && selectedTargetUnit.value}
          onChange={(value) => setSelectedTargetUnit({ value })}
        >
          <PicklistOption
            name="header"
            label={t('entry.office')}
            variant="header"
          />
          <PicklistOption name="all" label={t('oldEntries.all')} />
          {relatedTargetUnits.map((item) => (
            <PicklistOption
              name={item.name}
              label={item.name}
              key={item.id}
              value={item.id}
            />
          ))}
        </Picklist>
        <Picklist
          placeholder={t('oldEntries.select')}
          label={t('entry.nameOfEvent')}
          style={{
            maxWidth: '300px',
            width: '100%',
            height: '100%',
          }}
          value={eventName && eventName.value}
          onChange={(value) => setEventName({ value })}
        >
          <PicklistOption
            name="header"
            label={t('entry.nameOfEvent')}
            variant="header"
          />
          <PicklistOption name="all" label={t('oldEntries.all')} />
          {targetUnitEventNames.map((item) => (
            <PicklistOption name={item.name} label={item.name} key={item.id} />
          ))}
        </Picklist>
      </div>
      <div className="divider-container">
        <div className="horizontal-line-wide" />
      </div>

      <h1>{`${t('oldEntries.entryCount')}: ${dbResult && dbResult.count}`}</h1>

      <h2>
        {`${t('oldEntries.adults')}: ${
          dbResult &&
          dbResult.results &&
          dbResult.results.summary &&
          dbResult.results.summary.totalAdults.cardOwnerAmountSum
        }`}
      </h2>
      <h2>
        {`${t('oldEntries.children')}: ${
          dbResult &&
          dbResult.results &&
          dbResult.results.summary &&
          dbResult.results.summary.totalChildren.childrenAmountSum
        }`}
      </h2>

      {filteredEvents.map((event) => (
        <div className="old-entries-list" key={event.id}>
          <Card
            title={
              event.eventName
                ? `${event.cardNumber} : ${event.eventName}`
                : `${event.cardNumber}`
            }
            style={{ padding: '0px 0px 0px 15px', textAlign: 'left' }}
          >
            <p>{`${t('entry.office')}: ${
              event.targetUnitName ? event.targetUnitName : '-'
            }`}</p>
            <p>{`${t('oldEntries.date')}: ${moment(event.eventDate).format(
              'DD.MM.yyyy'
            )}`}</p>
            <p>
              {`${t('oldEntries.time')}: ${
                event.eventTime ? event.eventTime : t('oldEntries.notKnown')
              }`}
            </p>
            <p style={{ paddingBottom: '10px' }}>{`${t(
              'oldEntries.amountOfChildren'
            )}: ${event.childrenAmount}`}</p>
          </Card>
        </div>
      ))}
      <div className="button-wrapper">
        {dbResult.previous && (
          <Button
            type="button"
            onClick={() => setQueryUrl(dbResult.previous)}
            variant="brand"
          >
            {t('oldEntries.previous')}
          </Button>
        )}
        {dbResult.next && (
          <Button
            type="button"
            onClick={() => setQueryUrl(dbResult.next)}
            variant="brand"
          >
            {t('oldEntries.next')}
          </Button>
        )}
      </div>
    </main>
  )
}

OldEntriesList.propTypes = {
  history: propTypes.instanceOf(Object).isRequired,
}

export default OldEntriesList
