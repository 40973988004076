/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from 'react-rainbow-components'
import { useTranslation } from 'react-i18next'
import Scanner from '../Scanner'
import * as API from '../../utilities/api'
import ErrorMessage from '../ErrorMessage'
import './styles.scss'

const LoginView = ({ setResult, errors, setErrors, setUserData }) => {
  const [showLoginForm, setShowLoginForm] = useState(true)
  const [infoText, setInfoText] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    // Show login error message for 3 seconds
    const timer = setTimeout(() => {
      setErrors({})
    }, 3000)
    return () => clearTimeout(timer)
  }, [errors, setErrors])

  const handleTypedInLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const res = await API.POST('api/v1/auth/sign_in', {
        email,
        password,
      })
      if (res && res.data) {
        setLoading(false)
        setUserData({ isLoggedIn: true, userEmail: res.data.data.email })
      } else {
        setErrors({
          login: t('login.loginFailed'),
        })
        setLoading(false)
        setUserData({ isLoggedIn: false, userEmail: '' })
      }
    } catch (err) {
      setErrors({
        login: t('login.loginFailed'),
      })
      setLoading(false)
      setUserData({ isLoggedIn: false, userEmail: '' })
    }
  }

  const handleQRScan = async (data) => {
    if (data) {
      setEmail(data)
      setShowLoginForm(true)
      setInfoText('Syötä vielä salasana!')
    }
  }

  return (
    <main className="login">
      {errors && (
        <div className="login-errors-container">
          <ErrorMessage msg={errors.login} />
        </div>
      )}
      {!showLoginForm && (
        <div className="login-with-scanner">
          <Scanner
            title={t('login.readLoginCode')}
            onRead={(value) => {
              setResult(value)
              handleQRScan(value)
            }}
          />
          {!showLoginForm && (
            <div className="divider-container">
              <div className="horizontal-line" />
              <h2>{t('login.or')}</h2>
              <div className="horizontal-line" />
            </div>
          )}
        </div>
      )}

      <div className="login-with-form">
        <Button
          className="login-form-toggle-button rainbow-m-around_medium"
          type="button"
          variant="brand"
          onClick={() => setShowLoginForm(!showLoginForm)}
          label={
            showLoginForm
              ? t('login.readLoginCode')
              : t('login.addLoginCredentials')
          }
        />
        {showLoginForm && (
          <div className="divider-container">
            <div className="horizontal-line" />
            <h2>{t('login.or')}</h2>
            <div className="horizontal-line" />
          </div>
        )}
        {showLoginForm && (
          <div className="login-form-container">
            <form
              className="login-form"
              onSubmit={(e) => handleTypedInLogin(e)}
            >
              {infoText && <h2 className="enter-password-text">{infoText}</h2>}
              <Input
                className="login-form-item"
                type="email"
                name="email"
                placeholder={t('login.email')}
                aria-label={t('login.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                className="login-form-item"
                type="password"
                name="password"
                placeholder={t('login.password')}
                aria-label={t('login.password')}
                value={password}
                style={{ border: `${infoText ? '2px solid #a0ccaf' : ''}` }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="brand"
                className="rainbow-m-around_medium login-form-toggle-button"
                aria-label={t('login.login')}
                type="submit"
                isLoading={loading}
                label={t('login.login')}
              />
            </form>
          </div>
        )}
      </div>
    </main>
  )
}

LoginView.propTypes = {
  setResult: PropTypes.func.isRequired,
  errors: PropTypes.shape({ login: PropTypes.string }).isRequired,
  setErrors: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
}

export default LoginView
