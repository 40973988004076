import React, { useState, useEffect } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Application } from 'react-rainbow-components'
import axios from 'axios'
import CultureOperatorView from './components/CultureOperatorView'
import LoginView from './components/LoginView'
import Entry from './components/Entry'
import Footer from './components/Footer'
import Logo from './components/Logo'
import OldEntries from './components/OldEntriesList'
import './App.scss'
import * as API from './utilities/api'
import fiFlag from './assets/fi-flag.svg'
import seFlag from './assets/se-flag.svg'
import enFlag from './assets/en-flag.svg'

const ProtectedRoute = ({ component: ProtectedComponent, login, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!login) {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
      return <ProtectedComponent {...props} {...rest} />
    }}
  />
)

const theme = {
  rainbow: {
    palette: {
      brand: '#5b7065',
      mainBackground: '#fff',
    },
  },
}

const App = () => {
  const { t, i18n } = useTranslation()
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }
  // QR scanner result
  const [result, setResult] = useState('')
  // Current card
  const [card, setCard] = useState(null)
  // Current errors or warnings to show
  const [errors, setErrors] = useState({})
  // Current info messages to show
  const [infoMessages, setInfoMessages] = useState({})
  // Is user logged in
  const [userData, setUserData] = useState({ isLoggedIn: false, userEmail: '' })

  useEffect(() => {
    i18n.changeLanguage('fi')
  }, [i18n])

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const res = await API.GET('api/v1/auth/validate_token')
        setUserData({
          isLoggedIn: !!(res && res.data),
          userEmail: res.data?.data?.email || '',
        })
      } catch (err) {
        setUserData({ isLoggedIn: false, userEmail: '' })
      }
    }
    checkLoginStatus()
  }, [])

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        setUserData({ isLoggedIn: false, userEmail: '' })
      }
      return Promise.reject(error)
    }
  )

  return (
    <Application theme={theme}>
      <div className="grid-container">
        <div className="language">
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              margin: '10px 0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
            type="button"
            onClick={() => changeLanguage('fi')}
            aria-label={t('language.toFinish')}
          >
            <img
              style={{
                width: '40px',
                padding: '5px',
                borderBottom:
                  i18n.language === 'fi' ? '2px solid #5b7065' : 'none',
              }}
              src={fiFlag}
              alt="Finnish"
            />
          </button>
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              margin: '10px 0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
            type="button"
            onClick={() => changeLanguage('sv')}
            aria-label={t('language.toSwedish')}
          >
            <img
              style={{
                width: '40px',
                padding: '5px',
                borderBottom:
                  i18n.language === 'sv' ? '2px solid #5b7065' : 'none',
              }}
              src={seFlag}
              alt="Swedish"
            />
          </button>
          <button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              margin: '10px 0',
              paddingTop: '0',
              paddingBottom: '0',
            }}
            type="button"
            onClick={() => changeLanguage('en')}
            aria-label={t('language.toEnglish')}
          >
            <img
              style={{
                width: '40px',
                padding: '5px',
                borderBottom:
                  i18n.language === 'en' ? '2px solid #5b7065' : 'none',
              }}
              src={enFlag}
              alt="English"
            />
          </button>
        </div>
        <Logo />
        <Router>
          {userData.isLoggedIn ? (
            <Redirect to="/operator" />
          ) : (
            <Redirect to="/" />
          )}
          <Switch>
            <Route exact path="/">
              <LoginView
                setResult={(value) => {
                  setResult(value)
                }}
                errors={errors}
                setErrors={setErrors}
                setUserData={setUserData}
              />
            </Route>
            <ProtectedRoute
              exact
              login={userData.isLoggedIn}
              currentUserEmail={userData.userEmail}
              path="/operator"
              component={CultureOperatorView}
              result={result}
              handleSetCard={setCard}
              card={card}
              setErrors={setErrors}
              errors={errors}
              infoMessages={infoMessages}
              setInfoMessages={setInfoMessages}
              setUserData={setUserData}
            />
            <ProtectedRoute
              exact
              login={userData.isLoggedIn}
              path="/operator/entry"
              component={Entry}
              card={card}
              handleSetCard={setCard}
              errors={errors}
              setErrors={setErrors}
              infoMessages={infoMessages}
              setInfoMessages={setInfoMessages}
            />
            <ProtectedRoute
              exact
              login={userData.isLoggedIn}
              path="/operator/entries"
              component={OldEntries}
            />
          </Switch>
        </Router>
        <Footer />
      </div>
    </Application>
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  login: PropTypes.bool.isRequired,
}

export default App
